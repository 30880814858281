<template>
  <div v-if="positionList.length > 1">
    <div class="home-title">
      <!-- 本周热门 -->
      {{ positionMap[positionId.lovelyListId] }}
      <span
        class="home-more"
        @click="
          $router.push({
            name: nextMoreName,
            params: { tab: positionId.lovelyListId }
          })
        "
      >
        MORE
        <img :src="require('@/assets/images/路径@2x.png')" />
      </span>
    </div>
    <div class="home-lovely">
      <VideoCard
        theme="lovely"
        v-for="(item, index) in lovelyList"
        :data="item"
        :key="index"
        :nextRouteName="nextRouteName"
      />
    </div>
  </div>
</template>

<script>
import VideoCard from "../../components/VideoCard";
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  components: {
    VideoCard
  },
  computed: {
    ...mapState("home", ["positionList", "positionMap", "positionId"]),
    lovelyList() {
      return this.$store.state.home.lovelyList.slice(0, 6);
    },
    nextRouteName() {
      const routePath = this.$route.path;
      if (routePath == "/") {
        return "rdhyMedia";
      } else {
        return "Media";
      }
    },
    nextMoreName() {
      const routePath = this.$route.path;
      if (routePath == "/" || "") {
        return "rdhyMore";
      } else {
        return "More";
      }
    }
  },
  methods: {
    arrTrans(baseArray) {
      let len = baseArray.length;
      let n = 4; //每行显示4个
      let lineNum = len % n === 0 ? len / n : Math.floor(len / n + 1);
      let res = [];
      for (let i = 0; i < lineNum; i++) {
        let temp = baseArray.slice(i * n, i * n + n);
        res.push(temp);
      }
      return res;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/modules/home";

.home-lovely::after {
  content: "";
  width: 323px;
}

.home-lovely {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 385px + 323px * 2;
}
</style>

<template>
  <div v-if="positionList.length > 0">
    <div class="home-title">
      <!-- 新课免费试听 -->
      {{ positionMap[positionId.courseListId] }}
      <span
        class="home-more"
        @click="
          $router.push({
            name: nextMoreName,
            params: { tab: positionId.courseListId }
          })
        "
      >
        MORE
        <img :src="require('@/assets/images/路径@2x.png')" />
      </span>
    </div>
    <div class="home-course">
      <VideoCard
        theme="large"
        :data="courseList[0] || {}"
        :nextRouteName="nextRouteName"
      />
      <div class="right">
        <!-- <swiper ref="swiper" :options="swiperOptions" style="height: 500px;">
          <swiper-slide :key="index" v-for="(banners, index) in swiperList">
            <VideoCard :data="banners[0]" />
            {{ index }}
          </swiper-slide>
        </swiper> -->
        <VideoCard
          theme="course"
          v-for="(item, index) in list"
          :data="item"
          :key="index"
          :nextRouteName="nextRouteName"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VideoCard from "../../components/VideoCard";
import { mapState } from "vuex";

export default {
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      }
    };
  },
  components: {
    VideoCard
  },
  computed: {
    ...mapState("home", ["positionList", "positionMap", "positionId"]),
    courseList() {
      return this.$store.state.home.courseList;
    },
    swiperList() {
      return (
        this.arrTrans(this.courseList.slice(1, this.courseList.length)) || []
      );
    },
    list() {
      return (this.$store.state.home.courseList || []).slice(1, 5);
    },
    nextRouteName() {
      const routePath = this.$route.path;
      if (routePath == "/") {
        return "rdhyMedia";
      } else {
        return "Media";
      }
    },
    nextMoreName() {
      const routePath = this.$route.path;
      if (routePath == "/" || "") {
        return "rdhyMore";
      } else {
        return "More";
      }
    }
  },
  methods: {
    arrTrans(baseArray) {
      let len = baseArray.length;
      let n = 4; //每行显示4个
      let lineNum = len % n === 0 ? len / n : Math.floor(len / n + 1);
      let res = [];
      for (let i = 0; i < lineNum; i++) {
        let temp = baseArray.slice(i * n, i * n + n);
        res.push(temp);
      }
      return res;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/modules/home";

.home-course {
  display: flex;

  .right {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 42px;
    width: 677px;
  }
}
</style>

<template>
  <div>
    <div class="home-title">
      <!-- 更多精选 -->
      {{ data.title }}
      <span
        class="home-more"
        @click="
          $router.push({
            name: nextMoreName,
            params: { tab: data.positionId }
          })
        "
      >
        MORE
        <img :src="require('@/assets/images/路径@2x.png')" />
      </span>
    </div>
    <div class="home-case">
      <VideoCard
        theme="lovely"
        v-for="(item, index) in caseList"
        :data="item"
        :key="index"
        :nextRouteName="nextRouteName"
      />
      <div class="pick" v-if="caseList.length == 2"></div>
    </div>
  </div>
</template>

<script>
import VideoCard from "../../components/VideoCard";
import { mapState } from "vuex";

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VideoCard
  },
  computed: {
    ...mapState("home", [
      "positionList",
      "positionMap",
      "positionId",
      "blockListMap"
    ]),
    caseList() {
      // return this.$store.state.home.caseList.slice(0, 4);
      return this.blockListMap[this.data.positionId]?.slice(0, 4);
    },
    nextRouteName() {
      const routePath = this.$route.path;
      if (routePath == "/" || "") {
        return "rdhyMedia";
      } else {
        return "Media";
      }
    },
    nextMoreName() {
      const routePath = this.$route.path;
      if (routePath == "/" || "") {
        return "rdhyMore";
      } else {
        return "More";
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../assets/styles/modules/home";

.home-case:after {
  content: "";
  width: 323px;
}

.pick {
  width: 323px;
  overflow: hidden;
}

.home-case {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}
</style>

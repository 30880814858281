<template>
  <div class="video" :class="theme">
    <div
      class="video-cover"
      :style="{
        backgroundImage: 'url(' + bgUrl + ')'
      }"
      @click="openVideo"
    >
      <div class="video-cover-livestate">
        <LiveStatus :liveStatus="data.liveStatus" />
      </div>
      <div class="video-cover-box">
        <div class="video-cover-teacher">
          <span>{{ data.teacher || data.liveLecturer }}</span>
        </div>
        <div class="video-cover-pv">
          <div class="video-cover-pv-icon">
            <img src="@/assets/images/fire.png" alt="" />
          </div>
          <div class="video-cover-pv-number">
            <span>{{ data.pv || data.livePlayPv || data.livePlayUv }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="video-bg">
      <div class="video-bg-title" @click="openVideo">
        <span>{{ data.title || data.liveTitle }}</span>
      </div>
      <div class="video-bg-box">
        <div class="video-bg-time">
          <span>{{
            (data.showDate || data.liveBeginTime)
              | formatTime("YYYY-MM-DD HH:mm:ss")
          }}</span>
        </div>
        <div class="video-bg-price">
          <Price :price="data.price" :discountPrice="data.discountPrice" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LiveStatus from "@/components/LiveStatus";
import Price from "@/components/Price";
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    theme: {
      type: String,
      default: ""
    },
    nextRouteName: {
      type: String,
      default: "Media"
    }
  },
  components: {
    LiveStatus,
    Price
  },
  data() {
    return {
      courseType: ""
    };
  },
  computed: {
    bgUrl() {
      return (
        (this.data.coverUrl &&
          this.data.coverUrl.replace("(", "\\(").replace(")", "\\)")) ||
        (this.data.liveImg &&
          this.data.liveImg.replace("(", "\\(").replace(")", "\\)"))
      );
    }
  },
  methods: {
    openVideo() {
      if (this.data.liveStatus == 7 || this.data.bizType == 12) {
        this.courseType = "course";
      }
      if (this.data.liveStatus == 6 || this.data.bizType == 13) {
        this.courseType = "rrs";
      }
      if (this.data.liveStatus < 6 || this.data.bizType == 11) {
        this.courseType = "live";
      }
      if (this.data.liveStatus == 8 || this.data.bizType == 14) {
        this.courseType = "ott";
      }
      this.$router.push({
        name: this.nextRouteName,
        query: {
          id: this.data.bizId || this.data.courseId,
          courseType: this.courseType
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.video {
  box-shadow: 0 1px 20px rgba(143, 150, 163, 0.2);
  display: flex;
  flex-direction: column;
  width: 332px;
}

.video-cover {
  background: #d8d8d8;
  background-position: center;
  background-size: contain;
  background-size: cover;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 186px;
  justify-content: space-between;
  overflow: hidden;
}

.video-cover-livestate {
  display: flex;
  justify-content: flex-end;
  margin: 15px 10px 0 0;
}

.video-cover-box {
  align-items: flex-end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );

  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 0 10px 6px 15px;
}

.video-cover-teacher {
  color: #fff;
  font-size: 14px;
  max-width: 166px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.video-cover-pv {
  align-items: center;
  display: flex;
}

.video-cover-pv-icon {
  display: flex;
  margin-bottom: 2px;
}

.video-cover-pv-icon img {
  height: 14px;
  width: 14px;
}

.video-cover-pv-number {
  color: #fff;
  font-size: 14px;
  // font-weight: 500;
}

.video-cover-type {
  background: #e07471;
  border-radius: 17px;
  display: flex;
}

.video-cover-type-item {
  display: flex;
  padding: 1px 9px;
}

.video-cover-type-item span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  max-width: 323px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.video-bg {
  background: #fff;
  border-radius: 0 0 10px 10px;
  // box-shadow: 0 1px 20px 0 rgba(143, 150, 163, 0.2);
  flex: 1;
  padding: 16px 16px 14px;
}

.video-bg-title {
  color: #8f9bb3;
  display: flex;
  font-size: 17px;
  line-height: 25px;

  span {
    cursor: pointer;
    max-width: 323px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.video-bg-box {
  display: flex;
  justify-content: space-between;
}

.video-bg-time {
  color: #8f9bb3;
  display: flex;
  font-size: 15px;
  line-height: 21px;
  margin-top: 8px;
}

.video-bg-price {
  align-items: flex-end;
  display: flex;
  font-size: 17px;
  font-weight: bold;
  line-height: 17px;
  margin-bottom: 2px;
  opacity: 1;
}

.large {
  height: 507px;
  width: 665px;

  .video-cover {
    border-radius: 5px 5px 0 0;
    box-sizing: border-box;
    height: 372px;
  }

  .video-cover-box {
    padding-bottom: 16px;
  }

  .video-cover-type-item {
    display: flex;
    padding: 7px 15px;
  }

  .video-cover-livestate {
    margin: 15px 20px 0 0;
  }

  .video-cover-box {
    padding: 0 20px 15px;
  }

  .video-cover-teacher,
  .video-cover-pv-number {
    font-size: 18px;
  }

  .video-cover-pv-icon img {
    height: 22px;
    width: 22px;
  }

  .video-bg-title {
    color: #1f2e4d;
    font-size: 18px;
    line-height: 26px;
    opacity: 1;
    padding-top: 30px;
  }

  .video-bg-time {
    color: #8f9bb3;
    font-size: 16px;
    line-height: 22px;
    opacity: 1;
    padding-top: 10px;
  }
}

.home {
  box-shadow: none;
  height: 260px;
  width: 323px;

  .video-cover {
    border-radius: 5px;
    box-sizing: border-box;
    height: 181px;
  }

  .video-bg {
    background: none;
    padding: 13px 0 0;
  }
}

.lovely {
  box-shadow: none;
  height: 275px;
  width: 323px;

  .video-cover {
    background-size: cover;
    border-radius: 5px;
    box-sizing: border-box;
    height: 181px;
  }

  .video-bg {
    background: none;
    padding: 14px 0 0;
  }
}

.usercenter {
  height: 295px;
  margin-bottom: 33px;
  width: 331px;
}

.course {
  box-shadow: none;
  height: 260px;
  width: 323px;

  .video-cover {
    border-radius: 5px;
    box-sizing: border-box;
    height: 181px;
  }

  .video-bg {
    background: none;
    padding: 13px 0 0;
  }

  .video-bg-title span {
    max-width: 323px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>

<template>
  <div class="banner">
    <swiper ref="mySwiper" :options="swiperOptions" class="swiper-box">
      <swiper-slide :key="index" v-for="(banner, index) in bannerList">
        <!-- <img
          :src="banner.imgSy"
          @click="viewDetails(banner.id)"
          class="swiper-banner"
        /> -->
        <div
          class="banner-image"
          @click="viewDetails(banner)"
          :style="{
            backgroundImage: 'url(' + (banner.url || banner.imgSy) + ')'
          }"
        ></div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  props: {
    shop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      banners: ["1", "2", "3", "4", "5"],
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      }
    };
  },
  computed: {
    bannerList() {
      return this.$store.state.home.bannerList;
    },
    nextRouteName() {
      const routePath = this.$route.path;
      if (routePath == "/") {
        return "rdhyBannerList";
      } else {
        return "BannerList";
      }
    }
  },
  methods: {
    viewDetails({ id, urlSyAble, urlSy }) {
      if (urlSyAble == 1) {
        window.open(urlSy);
      } else {
        this.$router.push({ name: this.nextRouteName, params: { id } });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  border-radius: 15px;
  height: 500px;
  margin-top: 50px;
  overflow: hidden;
}

.swiper-box {
  height: 100%;
  width: 100%;
}

.banner-image {
  background-position: center;
  background-size: cover;
  cursor: pointer;
  height: 100%;
}

::v-deep {
  .swiper-pagination-bullet {
    background: #fff;
    border-radius: 5px;
    height: 8px;
    width: 40px;
  }

  .swiper-pagination-bullet-active {
    background: #fff;
    border-radius: 5px;
    height: 8px;
    width: 40px;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 24px;
  }
}

.swiper-banner {
  cursor: pointer;
  width: 100%;
}
</style>

<template>
  <div v-if="positionList.length > 2">
    <div class="home-title">
      {{ positionMap[positionId.topListId] }}
      <span
        class="home-more"
        @click="
          $router.push({
            name: nextMoreName,
            params: { tab: positionId.topListId }
          })
        "
      >
        MORE
        <img :src="require('@/assets/images/路径@2x.png')" />
      </span>
    </div>
    <div class="home-top">
      <div class="top-item" v-for="(item, index) in topList" :key="index">
        <div
          class="left"
          :style="{
            color:
              index == 0
                ? '#E07471'
                : index == 1
                ? '#F39965'
                : index == 2
                ? '#F2BB73'
                : '#e2e5eb'
          }"
        >
          {{ index + 1 }}
        </div>
        <div class="right">
          <div class="title" @click="openVideo(item)" v-popover:popover>
            {{ item.liveTitle }}
          </div>
          <div class="box">
            <div class="uv">
              <div style="padding-top:2px">
                <img src="@/assets/images/火@2x.png" alt="" />
              </div>
              <div>{{ item.livePlayPv }}</div>
            </div>

            <div class="price">
              <Price :price="item.price" :discountPrice="item.discountPrice" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Price from "@/components/Price";
import { mapState } from "vuex";

export default {
  data() {
    return {
      typeMap: {
        11: "live",
        12: "course",
        13: "rrs",
        14: "ott"
      }
    };
  },
  components: {
    Price
  },
  computed: {
    ...mapState("home", ["positionList", "positionMap", "positionId"]),
    topList() {
      return this.$store.state.home.topList.slice(0, 5);
    },
    nextRouteName() {
      const routePath = this.$route.path;
      if (routePath == "/") {
        return "rdhyMedia";
      } else {
        return "Media";
      }
    },
    nextMoreName() {
      const routePath = this.$route.path;
      if (routePath == "/" || "") {
        return "rdhyMore";
      } else {
        return "More";
      }
    }
  },
  methods: {
    openVideo(item) {
      this.$router.push({
        name: this.nextRouteName,
        query: {
          id: item.bizId || item.courseId,
          courseType: this.typeMap[item.bizType],
          shopId: this.$route.query.shopId
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/modules/home";

.home-top {
  width: 323px;
}

.top-item {
  border-bottom: 1px solid rgba($color: #37f, $alpha: 0.3);
  display: flex;
  height: 106px;

  .left {
    color: #e2e5eb;
    font-size: 72px;
    line-height: 102px;
    margin: 0 10px;
    text-align: center;
    width: 52px;
  }

  .right {
    flex: 1;
    padding-left: 10px;

    .title {
      color: #1f2e4d;
      cursor: pointer;
      font-size: 18px;
      line-height: 26px;
      margin-top: 33px;
      max-width: 240px;
      max-width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .uv {
      display: flex;
      color: #8f9bb3;
      font-size: 18px;
      line-height: 26px;
      margin-top: 5px;
      align-items: center;

      // img {
      //   margin-right: 9px;
      //   width: 16px;
      // }
    }
  }
}

.uv img {
  margin-right: 9px;
  width: 16px;
  height: 20px;
}

.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price {
  align-items: flex-end;
  display: flex;
  margin-bottom: 2px;
}
</style>

<template>
  <div class="container">
    <Banner />
    <CourseList />
    <div class="hot-box">
      <LovelyList />
      <TopList />
    </div>
    <div>
      <CaseList
        v-for="(item, index) in positionId.caseListId"
        :data="item"
        :key="index"
      />
    </div>
  </div>
</template>

<script>
import Banner from "./Banner";
import LovelyList from "./LovelyList";
import CourseList from "./CourseList";
import TopList from "./TopList";
import CaseList from "./CaseList";
import { mapActions, mapState } from "vuex";
export default {
  props: {
    // 1-app ，2-课程市场 ，3-门户
    terminal: {
      type: Number,
      default: 2
    }
  },

  components: {
    Banner,
    LovelyList,
    CourseList,
    TopList,
    CaseList
  },
  data() {
    return {
      shop: false
    };
  },
  computed: {
    ...mapState("home", [
      "positionList",
      "positionMap",
      "positionId",
      "blockListMap"
    ])
  },
  methods: {
    ...mapActions("home", ["getShopData", "getHomeData"])
  },
  mounted() {
    this.getHomeData(this.terminal);
  }
};
</script>

<style lang="scss" scoped>
.hot-box {
  display: flex;
  justify-content: space-between;
}
</style>

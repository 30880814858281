<template>
  <div class="price">
    <div>
      <span
        v-if="
          (discountPrice == null && price > 0) ||
            (discountPrice == price && discountPrice != 0 && price != 0)
        "
        style="color: #ed5656;"
        >&yen; {{ price }}</span
      >
    </div>
    <div
      v-if="
        (discountPrice == 0 && price == 0) ||
          (discountPrice == null && price == 0)
      "
    >
      <span style="color: #ed5656;">免费</span>
    </div>
    <div v-if="discountPrice > 0 && discountPrice !== price">
      <span style="color: #ed5656;">&yen;{{ discountPrice }}</span>
    </div>
    <div v-if="discountPrice == 0 && price > 0 && discountPrice !== price">
      <span style="color: #ed5656;">免费</span>
    </div>
    <div
      v-if="discountPrice >= 0 && price > 0 && discountPrice !== price"
      class="price-origin"
    >
      <span>&yen;{{ price }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    discountPrice: {
      type: Number,
      default: 0
    },
    price: {
      type: [String, Number],
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
.price {
  display: flex;
  font-size: 17px;
  font-weight: bold;
  line-height: 17px;
}

.price-origin {
  color: #999;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  padding-left: 4px;
  text-decoration: line-through;
}
</style>

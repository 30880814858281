<template>
  <div class="livestatus">
    <div
      v-if="liveStatus == 0"
      class="livestatus-item"
      style="background-color: #f0b71d;"
    >
      <span>即将开播</span>
    </div>
    <div
      v-if="liveStatus == 1"
      class="livestatus-item"
      style="background-color: #03a9ff;"
    >
      <span>直播中</span>
    </div>
    <div
      v-if="liveStatus == 2"
      class="livestatus-item"
      style="background-color: #546e96;"
    >
      <span>直播结束</span>
    </div>
    <div
      v-if="liveStatus == 3"
      class="livestatus-item"
      style="background-color: #35d597;"
    >
      <span>回放</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    liveStatus: {
      type: Number,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
.livestatus {
  display: flex;
}

.livestatus-item {
  border-radius: 10px;
  color: #fff;
  display: flex;
  font-size: 14px;
  line-height: 14px;
  padding: 4px 8px;
}
</style>

<template>
  <div class="if-box">
    <el-upload
      v-loading="loading"
      class="avatar-uploader"
      action="https://jsonplaceholder.typicode.com/posts/"
      :show-file-list="false"
      :before-upload="handleChange"
    >
      <img v-if="value" :src="value" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon" />
      <div v-if="value" class="tip">点击更换</div>
    </el-upload>
    <div class="right">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { getStsAssume } from "@/api/index";
import OSS from "ali-oss";
export default {
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    handleChange(file) {
      const isNotImage = file.type.includes("image");
      // const isImage = file.type === "image/jpeg" || file.type === "image/png";
      // console.log("file.type", file.type);
      console.log("isImage", isNotImage);
      if (!isNotImage) {
        this.$message.error("上传头像图片只能是图片格式!");
        return;
      }

      console.log("file",file);
      this.loading = true;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // 确保文件成功获取，base64数据量比较大
      reader.onload = () => {
        getStsAssume().then(res => {
          const imgClient = new OSS({
            region: "oss-cn-beijing",
            // 云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
            accessKeyId: res.AccessKeyId,
            accessKeySecret: res.AccessKeySecret,
            stsToken: res.SecurityToken,
            bucket: "haierzzfb-media"
          });
          const suffix = file.name.substr(file.name.lastIndexOf(".")+1);
          // const imgUrl = "live/" + new Date().valueOf() + "_" + file.name;
          const imgUrl = "live/" + new Date().valueOf()  + "." + suffix;
          console.log("filename",imgUrl)
          imgClient
            .put(imgUrl, file)
            .then(res => {
              this.$emit("input", res.res.requestUrls[0]);
              this.loading = false;
            })
            .catch(err => {
              console.error(err);
              alert();
            });
        });
      };
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

::v-deep .el-upload:hover {
  border-color: #409eff;
}

::v-deep .avatar-uploader-icon {
  color: #8c939d;
  font-size: 28px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  width: 178px;
}

.avatar {
  display: block;
  height: 178px;
  max-width: 300px;
}

.if-box {
  display: flex;
}

.tip {
  background: rgba($color: #000, $alpha: 0.3);
  bottom: 0;
  color: #fff;
  left: 0;
  position: absolute;
  right: 0;
}

.right {
  position: relative;
}
</style>

<template>
  <footer>
    <div class="container">
      <div class="logo_bottom">
        <!-- <img src="@/assets/images/logo_bottom@2x.png" alt="" /> -->
      </div>
      <div class="copyright">
        <div style="text-align: center;">
          <div class="agreement-item" @click="openICP">鲁ICP备2023011323号-2</div>
          |
          <div class="agreement-item" @click="openLGW">
            <img
              src="@/assets/images/备案图标.png"
              alt=""
              style="vertical-align:middle"
            />
            鲁公网安备 37021202001354号
          </div>
          | Copyright@ 海尔集团 All Rights Reserved | Tel:0532-88931230
        </div>
        <div class="agreement">
          <div class="agreement-item" @click="platformAgree">
            《人单合一平台服务协议》
          </div>
          <div class="agreement-item" @click="contentManage">
            《人单合一平台内容管理规定》
          </div>
          <div class="agreement-item" @click="operationStandard">
            《人单合一平台运营规范》
          </div>
          <div class="agreement-item" @click="privacyagree">《隐私协议》</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    platformAgree() {
      window.open("人单合一平台服务协议", "_blank");
    },
    contentManage() {
      window.open("人单合一平台内容管理规定", "_blank");
    },
    operationStandard() {
      window.open("人单合一平台运营规范", "_blank");
    },
    privacyagree() {
      window.open("人单合一隐私协议", "_blank");
    },
    openICP() {
      window.open("https://beian.miit.gov.cn/", "_blank");
    },
    openLGW() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37021202001354",
        "_blank"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
footer {
  align-items: center;
  // background: var(--footer-background);
  display: flex;
  height: 80px;
  justify-content: center;
  // margin-top: 50px;
  position: relative;
  z-index: 999;
}

.container {
  display: flex;
  justify-content: center;
}

.logo_bottom {
  display: flex;
  background: var(--footer-logo-background) no-repeat;
  background-size: cover;
  height: 49px;
  width: 56px;
}

// .logo_bottom img {
//   height: 49px;
//   width: 56px;
// }

.copyright {
  color: #8f9bb3;
  display: flex;
  flex-direction: column;
  font: 14px;
  justify-content: center;
  line-height: 20px;
  text-shadow: 0 -2px 4px rgba(143, 150, 163, 0.2);
}

.agreement {
  display: flex;
  justify-content: center;
}

.agreement-item {
  cursor: pointer;
  display: inline-block;
  &:hover {
    color: #37f;
  }
}
</style>
